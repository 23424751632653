import { Layout } from '@components/layout';
import { Fab } from '@components/shared';
import {
    Hero,
    FromUs,
    Contact,
    Employees,
    Partners,
    Targets,
    PortfolioPreview,
    BlogPreview,
    Pricing,
    About,
    Steps,
    BuildModal,
    WhatWeDo,
    WrappedChatWidget,
} from '@components/standard';
import React from 'react';

const IndexPage = ({ pageContext, location }) => {
    const { page, posts } = pageContext;

    return (
        <Layout {...page}>
            <BuildModal />
            <Hero />
            <FromUs />
            <Targets />
            {/* <About /> */}
            {/* <Partners /> */}
            {/* <Employees /> */}
            <Steps />
            <WhatWeDo />
            <PortfolioPreview />
            <BlogPreview posts={posts} />
            <Pricing />
            <Contact />
            <Fab />

            {/* <WrappedChatWidget /> */}
        </Layout>
    );
};

export default IndexPage;
